




































import { Component, Prop, Vue } from "vue-property-decorator";
import { formatsecd2Text } from "@/utils/utils";
import { Message } from "@/api/message";
@Component({
  filters: {
    dealStatusColorFilter(val: number) {
      switch (val) {
        case 0:
          return "itemValueRed";
        case 1:
          return "itemValueYellow";
          case 2:
          return "itemValueBlue";
        default:
          return "itemValueRed";
      }
    },
    dealIconFilter(val: number) {
      switch (val) {
        case 2:
          return require("@/assets/img/msg_error.png");
        case 1:
          return require("@/assets/img/msg_task.png");
        default:
          return require("@/assets/img/msg_system.png");
      }
    },
  },
})
export default class MessageBoard extends Vue {
  @Prop({
    type: Object,
    default: {
      id: 1,
      name: "",
      seriesNumber: "",
    },
  })
  private item!: Message;
  @Prop({
    type: Number,
    default: 0,
  })
  private index!: number; //显示返回按钮

  private skip(e: any) {
    let that = this;
    this.$emit(
      "clickItem",
      that.item
    );
  }
  private setText(e: any) {
    return formatsecd2Text(e)
  }
}
