import request from '@/utils/request';
import { CommonListRes, Page, CommonRes } from './common';
export interface GetFaultListReq {
    page: number;
    limit: number;
    startTimeBegin?: string;
    startTimeEnd?: string;
    stopTimeBegin?: string;
    stopTimeEnd?: string;
    state?: string;
    robotName?: string;
}
/**
 * 故障的结构体
 */
 export interface Fault {
    id: number;
    faultType: number;
    robotId: string;
    state: number;
    title: string;
    noteTaker: string;
    content: string;
    recordTime: string;
    faultTime: string;
    file: string;
    remark: string;
    contactInfo: string;
    address: string;
    robotEntity: {
      name: "",
      robotId: "",
    }
}
export interface Faulteq {
    id: number;
    remark: string;
}
export interface Uploadeq {
    file: File;
}
export interface FaultReq {
    faultType: number;
    robotId: string;
    state: number;
    title: string;
    noteTaker: string;
    content: string;
    recordTime: string;
    faultTime: string;
    file: string;
    remark: string;
    address: string;
    contactInfo: string;
}
export interface setFaultStateReq {
    state?: number;
}
export interface GetFaultByIdRes {
    msg: string;
    code: number;
    fault: Fault;
}
export interface UploadRes {
    msg: string;
    code: number;
    files: string[];
}
export interface UploadFileRes {
    msg: string;
    code: number;
    file: string;
}
/**
 * 搜索任务列表
 *
 * @export
 * @returns {Promise<CommonListRes<Page<Work>>>}
 */
 export function getFaultList(params: GetFaultListReq): Promise<CommonListRes<Page<Fault>>> {
    return request.get<CommonListRes<Page<Fault>>>('/fault/search', params);
}
/**
 * 新增故障记录
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function saveFault(params: FaultReq): Promise<CommonRes> {
    return request.post<CommonRes>('/fault/save', params);
}
/**
 * 修改故障记录
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function updateFault(params: Fault): Promise<CommonRes> {
    return request.patch<CommonRes>('/fault', params);
}
/**
 * 修改故障状态
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function setFaultState(params: setFaultStateReq, id: number): Promise<CommonRes> {
    return request.get<CommonRes>('/fault/state/' + id, params);
}
/**
 * 新增故障备注
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function addRemarks(params: Faulteq): Promise<CommonRes> {
    return request.post<CommonRes>('/fault/addRemarks/', params);
}
/**
 * 获取故障详情信息
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function getFault(id: number): Promise<GetFaultByIdRes> {
    return request.get<GetFaultByIdRes>('/fault/detail/' + id);
}
/**
 * 删除故障
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function deleteFault(ids: number[]): Promise<CommonRes> {
    return request.delete<CommonRes>('/fault', ids);
}
/**
 * 上传故障文件
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function uploadFile(params: File): Promise<UploadFileRes> {
    return request.mimePost2<UploadFileRes>('/file', params);
}
/**
 * 上传多个故障文件
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function uploadFiles(params: any[]): Promise<UploadRes> {
    return request.mimePost<UploadRes>('/file/list/upload', params);
}
/**
 * 上传多个故障文件
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
export function uploadRequestFiles(params: any[], robotId: string): Promise<UploadRes> {
    return request.mimePost<UploadRes>('/file/list/request/' + robotId, params);
}

