import request from '@/utils/request';
import { CommonListRes, Page, CommonRes } from './common';
/**
 * 任务的结构体
 */
 export interface Message {
    id: number;
    messageType: number;
    robotId: string;
    state: number;
    taskId: number;
    url: string;
    content: string;
    pushTime: string;
    faultTime: string;
}
export interface Messageeq {
    page: number;
    limit: number;
    timeZone: string;
}
export interface IsPushMessageEntity {
    isPushFalut: false;
    isPushTask: false;
}
/**
 * 获取message信息
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function getMessage(params: Messageeq): Promise<CommonListRes<Page<Message>>> {
    return request.get<CommonListRes<Page<Message>>>('/message/search/', params);
}
/**
 * 获取邮件通知
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
export function setEmailNt(params: IsPushMessageEntity): Promise<CommonRes> {
    return request.post<CommonRes>('/message/email/setting', params);
}