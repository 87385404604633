import request from '@/utils/request';
import { CommonListRes, Page, CommonRes } from './common';
export interface GetMaintenanceListReq {
    page: number;
    limit: number;
    startTimeBegin?: string;
    startTimeEnd?: string;
    noteTaker?: string;
    robotName?: string;
    faultType?: string;
}
/**
 * 故障的结构体
 */
 export interface Maintenance {
    id:number;
    companyName: string;
    robotId: string;
    machineModel: string;
    maintenanceTime: string,
    checkedBy: string;
    submitter: string;
    address: string;
    physicalInspect: string;
    inspectKeys: string;
    topCover: string;
    testButtons: string;
    inspectTablet: string;
    technicalPanel: string;
    checkForBattery: string;
    inspectWheel: string;
    inspectTankLeak: string;
    testSensors: string;
    inspectWaterTank: string;
    testBrush: string;
    inspectRobot: string;
    inspectQr: string;
    checkAutoLocation: string;
    testCharger: string;
    manualCleaning: string;
    autoCleaning: string;
    operateRobot: string;
    checkVirtualWall: string;
    checkAvoidance: string;
    testAutoDocking: string;
    testWaterPump: string;
    softwareUpgrade: string;
    refresher: string;
    adminInfo: string;
    clientInfo: string;
    robotEntity: {
      name: "",
      robotId: "",
    }
}
export interface Maintenanceeq {
    id: number;
    remark: string;
}
export interface Uploadeq {
    file: File;
}
export interface MaintenanceReq {
    companyName: string;
    robotId: string;
    machineModel: string;
    maintenanceTime: string,
    checkedBy: string;
    submitter: string;
    address: string;
    physicalInspect: string;
    inspectKeys: string;
    topCover: string;
    testButtons: string;
    inspectTablet: string;
    technicalPanel: string;
    checkForBattery: string;
    inspectWheel: string;
    inspectTankLeak: string;
    testSensors: string;
    inspectWaterTank: string;
    testBrush: string;
    inspectRobot: string;
    inspectQr: string;
    checkAutoLocation: string;
    testCharger: string;
    manualCleaning: string;
    autoCleaning: string;
    operateRobot: string;
    checkVirtualWall: string;
    checkAvoidance: string;
    testAutoDocking: string;
    testWaterPump: string;
    softwareUpgrade: string;
    refresher: string;
    adminInfo: string;
    clientInfo: string;
}
export interface MaintenanceInfoReq {
    affiliatedCompany?: string;
    robotId: string;
    deploymentDate?: string,
    maintenanceDate?: string;
    admin?: string;
    client?: string;
    warrantyPeriod?: string;
    guaranteePeriod?: string;
    telephone?: string;
    consumables?: string;
    fittings?: string;
}
export interface MaintenanceInfo{
    id?:number;
    affiliatedCompany?: string;
    robotId: string;
    deploymentDate?: string,
    maintenanceDate?: string;
    admin?: string;
    client?: string;
    warrantyPeriod?: string;
    guaranteePeriod?: string;
    telephone?: string;
    consumables?: string;
    fittings?: string;
}
export interface RobotInfo{
    lastUsage: string;
    lastMaintenance: string;
}
export interface GetMaintenanceByIdRes {
    msg: string;
    code: number;
    maintenance: Maintenance;
}
export interface MaintenanceInfoRes {
    msg: string;
    code: number;
    maintain: MaintenanceInfo;
}
export interface RobotInfoRes {
    msg: string;
    code: number;
    robotInfo: RobotInfo;
}
export interface UploadRes {
    msg: string;
    code: number;
    files: string[];
}
export interface UploadFileRes {
    msg: string;
    code: number;
    file: string;
}
/**
 * 搜索任务列表
 *
 * @export
 * @returns {Promise<CommonListRes<Page<Work>>>}
 */
 export function getMaintainList(params: GetMaintenanceListReq): Promise<CommonListRes<Page<Maintenance>>> {
    return request.get<CommonListRes<Page<Maintenance>>>('/maintain/search', params);
}
/**
 * 新增故障记录
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function saveMaintaink(params: MaintenanceReq): Promise<CommonRes> {
    return request.post<CommonRes>('/maintain/save', params);
}
/**
 * 保存维护资料
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
export function saveMaintainInfo(params: MaintenanceInfoReq): Promise<CommonRes> {
    return request.post<CommonRes>('/maintain/saveData', params);
}
/**
 * 获取维护资料
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
export function getMaintainInfo(id: string): Promise<MaintenanceInfoRes> {
    return request.get<MaintenanceInfoRes>('/maintain/maintainData/' + id);
}
/**
 * 获取机器人资料
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
export function getRobotInfo(id: string): Promise<RobotInfoRes> {
    return request.get<RobotInfoRes>('/maintain/robotInfo/' + id);
}
/**
 * 修改故障记录
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function updateMaintain(params: Maintenance): Promise<CommonRes> {
    return request.patch<CommonRes>('/maintain', params);
}
/**
 * 获取故障详情信息
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function getMaintenance(id: number): Promise<GetMaintenanceByIdRes> {
    return request.get<GetMaintenanceByIdRes>('/maintain/detail/' + id);
}
/**
 * 删除故障
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function deleteMaintain(ids: number[]): Promise<CommonRes> {
    return request.delete<CommonRes>('/maintain', ids);
}
/**
 * 上传故障文件
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function uploadFile(params: File): Promise<UploadFileRes> {
    return request.mimePost2<UploadFileRes>('/file', params);
}
/**
 * 上传多个故障文件
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function uploadFiles(params: any[]): Promise<UploadRes> {
    return request.mimePost<UploadRes>('/file/list/upload', params);
}
/**
 * 上传多个故障文件
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
export function uploadMaintainFiles(params: any[], robotId: string): Promise<UploadRes> {
    return request.mimePost<UploadRes>('/file/list/maintain/' + robotId, params);
}