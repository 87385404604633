import request from '@/utils/request';
import { CommonListRes, Page, CommonRes } from './common';
export interface GetRepairListReq {
    page: number;
    limit: number;
    startTimeBegin?: string;
    startTimeEnd?: string;
    noteTaker?: string;
    robotName?: string;
    faultType?: string;
}
/**
 * 故障的结构体
 */
 export interface Repair {
    id:number;
    companyName: string;
    robotId: string;
    machineModel: string;
    repairTime: string,
    checkedBy: string;
    submitter: string;
    address: string;
    adminInfo: string;
    entryList: string;
    robotEntity: {
      name: "",
      robotId: "",
    }
}
export interface Repaireq {
    id: number;
    remark: string;
}
export interface Uploadeq {
    file: File;
}
export interface RepairReq {
    companyName: string;
    robotId: string;
    machineModel: string;
    repairTime: string,
    checkedBy: string;
    submitter: string;
    address: string;
    adminInfo: string;
    entryList: string;
}
export interface GetRepairByIdRes {
    msg: string;
    code: number;
    repair: Repair;
}
export interface UploadRes {
    msg: string;
    code: number;
    files: string[];
}
export interface UploadFileRes {
    msg: string;
    code: number;
    file: string;
}
/**
 * 搜索任务列表
 *
 * @export
 * @returns {Promise<CommonListRes<Page<Work>>>}
 */
 export function getRepairList(params: GetRepairListReq): Promise<CommonListRes<Page<Repair>>> {
    return request.get<CommonListRes<Page<Repair>>>('/repair/search', params);
}
/**
 * 新增故障记录
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function saveRepairk(params: RepairReq): Promise<CommonRes> {
    return request.post<CommonRes>('/repair/save', params);
}
/**
 * 修改故障记录
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function updateRepair(params: Repair): Promise<CommonRes> {
    return request.patch<CommonRes>('/repair', params);
}
/**
 * 获取故障详情信息
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function getRepair(id: number): Promise<GetRepairByIdRes> {
    return request.get<GetRepairByIdRes>('/repair/detail/' + id);
}
/**
 * 删除故障
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function deleteRepair(ids: number[]): Promise<CommonRes> {
    return request.delete<CommonRes>('/repair', ids);
}
/**
 * 上传故障文件
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function uploadFile(params: File): Promise<UploadFileRes> {
    return request.mimePost2<UploadFileRes>('/file', params);
}

/**
 * 上传多个故障文件
 *
 * @export
 * @returns {Promise<CommonRes>}
 */
 export function uploadRepairFiles(params: any[], robotId: string): Promise<UploadRes> {
    return request.mimePost<UploadRes>('/file/list/repair/' + robotId, params);
}
